import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import {
  Flex,
  LogoutIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  WarningIcon,
} from 'hypr-toolkit/packages/uikit'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'state/profile/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'

import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenutItem'
// import WalletUserMenuItem from './WalletUserMenuItem'

const StyleMenu = styled(UIKitUserMenu)`
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  & + div {
    background: #be3144;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0 !important;
    overflow: hidden;
  }
`

const StyleUserMenuItem = styled(UserMenuItem)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
`

const StyleUserMenuDivider = styled(UserMenuDivider)`
  border-color: rgba(255, 255, 255, 0.4);
  margin: 0;
`

const StyleConnectWalletButton = styled(ConnectWalletButton)`
  ${({ theme }) => theme.devicesQueries.mobile} {
    height: 28px !important;
    border-radius: 6px;
    font-size: 12px;
  }
`

const UserMenu = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const avatarSrc = profile && profile.nft ? `/images/nfts/${profile.nft.images.sm}` : undefined
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  // const OOOContract = useOOO()
  const [name] = useState<string>()

  // const fetchOOO = useCallback(async (): Promise<void> => {
  //   if (OOOContract && account) {
  //     const hash = namehash(`${account.slice(2)}.addr.reverse`)
  //     const res = await OOOContract.name(hash)
  //     setName(res)
  //   }
  // }, [OOOContract, account])

  // useEffect(() => {
  //   fetchOOO()
  // }, [OOOContract, account, fetchOOO])

  if (!account) {
    return <StyleConnectWalletButton scale="sm" />
  }

  return (
    <StyleMenu account={name || account} avatarSrc={avatarSrc}>
      <StyleUserMenuItem as="button" onClick={onPresentWalletModal}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Wallet')}
          {hasLowBnbBalance && <WarningIcon color="warning" width="24px" />}
        </Flex>
      </StyleUserMenuItem>
      <StyleUserMenuItem as="button" onClick={onPresentTransactionModal}>
        {t('Transactions')}
      </StyleUserMenuItem>
      {/* <UserMenuDivider /> */}
      {/* <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} /> */}
      <StyleUserMenuDivider />
      <StyleUserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </StyleUserMenuItem>
    </StyleMenu>
  )
}

export default UserMenu
