import React, { useCallback, useState } from 'react'
import { Currency, Token } from 'hypr-toolkit/packages/swap-sdk'
import { InjectedModalProps, Button } from 'hypr-toolkit/packages/uikit'
import styled from 'styled-components'
import usePrevious from 'hooks/usePreviousValue'
import { TokenList } from '@uniswap/token-lists'
import { useTranslation } from 'contexts/Localization'
import Modal from 'components/NewModal'
import CurrencySearch from './CurrencySearch'
import ImportToken from './ImportToken'
import Manage from './Manage'
import ImportList from './ImportList'
import { CurrencyModalView } from './types'

const Footer = styled.div`
  width: 100%;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px 0;
  margin-top: -20px;
`

const FooterButton = styled(Button)`
  background: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
  height: auto;
`

interface CurrencySearchModalProps extends InjectedModalProps {
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
}

export default function CurrencySearchModal({
  onDismiss = () => null,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false,
}: CurrencySearchModalProps) {
  const [modalView, setModalView] = useState<CurrencyModalView>(CurrencyModalView.search)
  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onDismiss()
      onCurrencySelect(currency)
    },
    [onDismiss, onCurrencySelect],
  )

  // for token import view
  const prevView = usePrevious(modalView)

  // used for import token flow
  const [importToken, setImportToken] = useState<Token | undefined>()

  // used for import list
  const [importList, setImportList] = useState<TokenList | undefined>()
  const [listURL, setListUrl] = useState<string | undefined>()

  const { t } = useTranslation()

  const config = {
    [CurrencyModalView.search]: { title: t('Select a Token'), onBack: undefined },
    [CurrencyModalView.manage]: { title: t('Manage'), onBack: () => setModalView(CurrencyModalView.search) },
    [CurrencyModalView.importToken]: {
      title: t('Import Tokens'),
      onBack: () =>
        setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search),
    },
    [CurrencyModalView.importList]: { title: t('Import List'), onBack: () => setModalView(CurrencyModalView.search) },
  }
  return (
    <Modal
      onBack={config[modalView].onBack}
      title={config[modalView].title}
      onDismiss={onDismiss}
      footer={
        modalView === CurrencyModalView.search && (
          <Footer>
            <FooterButton
              onClick={() => setModalView(CurrencyModalView.manage)}
              // startIcon={<FooterButtonImg src="/images/edit.svg" />}
            >
              {t('Manage Tokens')}
            </FooterButton>
          </Footer>
        )
      }
    >
      {modalView === CurrencyModalView.search ? (
        <CurrencySearch
          onCurrencySelect={handleCurrencySelect}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={showCommonBases}
          showImportView={() => setModalView(CurrencyModalView.importToken)}
          setImportToken={setImportToken}
        />
      ) : modalView === CurrencyModalView.importToken && importToken ? (
        <ImportToken tokens={[importToken]} handleCurrencySelect={handleCurrencySelect} />
      ) : modalView === CurrencyModalView.importList && importList && listURL ? (
        <ImportList list={importList} listURL={listURL} onImport={() => setModalView(CurrencyModalView.manage)} />
      ) : modalView === CurrencyModalView.manage ? (
        <Manage
          setModalView={setModalView}
          setImportToken={setImportToken}
          setImportList={setImportList}
          setListUrl={setListUrl}
        />
      ) : (
        ''
      )}
    </Modal>
  )
}
