import React, { useState } from 'react'
import { ButtonMenu, ButtonMenuItem, InjectedModalProps, ModalBody } from 'hypr-toolkit/packages/uikit'
import { parseUnits } from 'ethers/lib/utils'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import Modal from 'components/NewModal'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const Tabs = styled.div`
  padding: 0 0 16px;
`

const StyleButtonMenu = styled(ButtonMenu)<{ activeIndex: number }>`
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  button {
    &:nth-child(${({ activeIndex }) => activeIndex+1}) {
      background-color: #760b1a;
      color: #ffffff;
    }
  }
`

const StyleButtonMenuItem = styled(ButtonMenuItem)``

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <Modal title="Your Wallet" onDismiss={onDismiss}>
      <Tabs>
        <StyleButtonMenu scale="sm" variant="subtle" onItemClick={handleClick} activeIndex={view} fullWidth>
          <StyleButtonMenuItem>{t('Wallet')}</StyleButtonMenuItem>
          <StyleButtonMenuItem>{t('Transactions')}</StyleButtonMenuItem>
        </StyleButtonMenu>
      </Tabs>
      <ModalBody maxWidth="400px" width="100%">
        {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
        {view === WalletView.TRANSACTIONS && <WalletTransactions />}
      </ModalBody>
    </Modal>
  )
}

export default WalletModal
