import React, { useState } from 'react'
import { Token } from 'hypr-toolkit/packages/swap-sdk'
import { ButtonMenu, ButtonMenuItem, ModalBody } from 'hypr-toolkit/packages/uikit'
import styled from 'styled-components'
import { TokenList } from '@uniswap/token-lists'
import { useTranslation } from 'contexts/Localization'
import ManageLists from './ManageLists'
import ManageTokens from './ManageTokens'
import { CurrencyModalView } from './types'

const Tab = styled(ButtonMenu)`
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  text-align: center;
  border: 0 none;
  margin: 0 0 20px;
`

const TabItem = styled(ButtonMenuItem)`
  background: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  height: 32px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  &:hover:not(:disabled):not(:active) {
    background: rgba(255, 255, 255, 0.3);
  }
`
const ActiveItem = styled(TabItem)`
  background: none;
  color: rgba(255, 255, 255, 0.6);
  &:hover:not(:disabled):not(:active) {
    background: rgba(255, 255, 255, 0.3);
  }
`

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
}) {
  const [showLists, setShowLists] = useState(true)

  const { t } = useTranslation()
  const Lists = showLists ? ActiveItem : TabItem
  const Tokens = !showLists ? ActiveItem : TabItem

  return (
    <ModalBody>
      <Tab activeIndex={showLists ? 0 : 1} onItemClick={() => setShowLists((prev) => !prev)}>
        <Lists>{t('Lists')}</Lists>
        <Tokens>{t('Tokens')}</Tokens>
      </Tab>
      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : (
        <ManageTokens setModalView={setModalView} setImportToken={setImportToken} />
      )}
    </ModalBody>
  )
}
