import { CHAINKEY, ChainId } from 'hypr-toolkit/packages/swap-sdk'
import BigNumber from 'bignumber.js/bignumber'
import { sample } from 'lodash'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})
export const ConfigChainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
export const chainKey = CHAINKEY.HYPR

export const BSC_BLOCK_TIME = 16
export const wallet_config: Record<
  ChainId,
  {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
> = {
  [ChainId.HyprMainnet]: {
    chainId: `0x${ChainId.HyprMainnet.toString(16)}`,
    chainName: 'Hypr',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.hypr.network'],
    blockExplorerUrls: ['https://explorer.hypr.network/'],
  },
  [ChainId.HyprTestnet]: {
    chainId: `0x${ChainId.HyprTestnet.toString(16)}`,
    chainName: 'Hypr Testnet (Goerli)',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://testnet-proposer.hypr.network:443/'],
    blockExplorerUrls: ['https://testnet-explorer.hypr.network/'],
  },
  [ChainId.HyprSepoliaTestnet]: {
    chainId: `0x${ChainId.HyprSepoliaTestnet.toString(16)}`,
    chainName: 'Hypr Testnet (Sepolia)',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-testnet.hypr.network/'],
    blockExplorerUrls: ['https://explorer-testnet.hypr.network/'],
  },
}
export const BASE_BSC_SCAN_URL = sample(wallet_config[ConfigChainId].blockExplorerUrls)
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const HYPR_PER_DAY = 50000
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = HYPR_PER_DAY * 365
export const BASE_URL = 'https://hyprswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
// export const DEFAULT_GAS_LIMIT = 1000_000
export const DEFAULT_GAS_LIMIT = 1000_000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const BRC20Fee = {
  hypr: 5,
}
