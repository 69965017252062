import { Currency, ETHER, Token } from 'hypr-toolkit/packages/swap-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { BASE_URL, ConfigChainId, chainKey } from 'config'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { getTokenLogoURLByUp } from '../../utils/getTokenLogoURL'
import HyprLogo from './HyprLogo'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? `${BASE_URL}${currency.logoURI}` : undefined,
  )
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER[ConfigChainId]) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURLByUp(currency.symbol)]
      }
      return [getTokenLogoURLByUp(currency.symbol)]
    }
    return []
  }, [currency, uriLocations])
  if (currency === ETHER[ConfigChainId] || currency?.symbol === chainKey) {
    return <HyprLogo width={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
