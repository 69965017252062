import React from 'react'
import { Flex, Button, Text } from 'hypr-toolkit/packages/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/user/hooks/helpers'
import { useGasPriceManager } from 'state/user/hooks'
import styled, { css } from 'styled-components'

const StyledButton = styled(Button)<{ cur: string }>`
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  box-shadow: none;
  ${({ cur }) =>
    cur === 'true' &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
    `};
`

const StyledFlexWrap = styled.div`
  width: 320px;
  overflow: hidden;
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('Default Transaction Speed (WEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <StyledFlexWrap>
        <StyledButton
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          cur={`${gasPrice === GAS_PRICE_GWEI.default}`}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </StyledButton>
        <StyledButton
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          cur={`${gasPrice === GAS_PRICE_GWEI.fast}`}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </StyledButton>
        <StyledButton
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          cur={`${gasPrice === GAS_PRICE_GWEI.instant}`}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </StyledButton>
      </StyledFlexWrap>
    </Flex>
  )
}

export default GasSettings
