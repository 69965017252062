import React from 'react'
import { Box, BoxProps } from 'hypr-toolkit/packages/uikit'

const Container: React.FC<BoxProps & { py?: any; mb?: any }> = ({ children, ...props }) => (
  <Box px={['16px', '24px']} mx="auto" maxWidth="1200px" {...props}>
    {children}
  </Box>
)

export default Container
