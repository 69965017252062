import { SerializedPoolConfig } from './types'

const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.hypr,
  //   earningToken: serializedTokens.hypr,
  //   contractAddress: {
  //     [ChainId.HyprMainnet]: '0x36ABBa64A05566e35Ad02A30fA55cD43aef96722',
  //     [ChainId.HyprTestnet]: '0x4E487E8cf4008F568c8DfA0B63ED6e1AEaAA8BAa',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
]

export default pools
