export const colors = {
  bgColor: '#F8F7FA',
  disabled: '#8A8799',
  light: '#9490A3',
  grey: '#565266',
  darkGrey: '#1E163D',
  brandYellow: '#BE3144',
}

export const fontFamily = 'Poppins,"SF Pro Display",Roboto,system-ui,KANIT ,Helvetica sans-serif'
export const geometry = {
  headerHeight: 56,
  footerHeight: 220,
  get tbHeight() {
    return this.footerHeight + this.headerHeight
  },
}

export const vw = (s: number) => `${(s / 414) * 100}vw`
