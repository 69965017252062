import { Currency, ETHER, Token } from 'hypr-toolkit/packages/swap-sdk'
import { ConfigChainId } from 'config'

export function currencyId(currency: Currency): string {
  if (currency === ETHER[ConfigChainId]) return 'ETH'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId
