import React from 'react'
import { Button, useModal } from 'hypr-toolkit/packages/uikit'
import TransactionsModal from './TransactionsModal'

import HistoryIcon from './history.svg'

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <Button variant="text" p={0} onClick={onPresentTransactionsModal} style={{ width: 24 }}>
        <img src={HistoryIcon} style={{ width: 24 }} alt="" />
      </Button>
    </>
  )
}

export default Transactions
