import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'hypr-toolkit/packages/uikit/dist/theme'
import theme2 from 'style/theme2'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
  }
  body {
    background-color: #303841;
    background-image: url(images/bg.svg);
    background-size: 100vw auto;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 0 0;

    ${({ theme }) => theme.devicesQueries.mobile} {
      background-image: url(images/bg2.svg);
      background-position:  0 -20vh;
    }

    img {
      height: auto;
      max-width: 100%;
    }

    &>iframe{
      display: none
    }
  }

  #swap-currency-input {
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }
  #swap-currency-output {
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }
  #add-liquidity-input-tokena { 
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }
  #add-liquidity-input-tokenb {
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }

  div[id="root"] {
    div[role="presentation"] {
      background-color: #000000 !important;
    }
    > div {
      // top header
      > nav > div { 
        // icon buttons
        // > div > button {
        //   margin-left: 18px;
        //   margin-right: 18px;
        // }
        // connect wallet button 
        > button {
          width: 115px;
          height: 28px !important;
          white-space: nowrap !important;
          margin-left: 2vw;
          font-size: 12px;
        }
      }

      // bottom footer
      > nav + div {
        // bg override
        > div > div { background-color: ${theme2.bg}; }
        > div > div + div { background-color: ${theme2.bg}; }
      }
      > nav + div + div {
        // bg override
        > div > div { background-color: ${theme2.bg}; }
        > div > div + div { background-color: ${theme2.bg}; }
      }
    }
    ${({ theme }) => theme.devicesQueries.mobile} {
      div[role="modalWrapper"] {
        top: auto;
      }
      div[role="presentation"]+div {
        width: 100%;
        max-width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  /* div[id="farm-card"] {
    & :first-child {
      background-color: ${theme2.cardBg};
    }
  } */
  a[id="join-pool-button"] {
    color: #000000 !important;
    > svg {
      filter: invert();
    }
  }
  nav {
    a[href^="/"]:hover {
      background-color: none !important;
    }
  }
  /* button{
    box-shadow: 0px 5px 20px rgba(220, 0, 88, 0.2) !important;
    border-radius: 12px !important;
  } */
`

export default GlobalStyle
