import { ChainId } from 'hypr-toolkit/packages/swap-sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()
const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HDX',
    lpAddresses: {
      [ChainId.HyprMainnet]: serializedTokens.hdx.address,
      [ChainId.HyprTestnet]: serializedTokens.hdx.address,
      [ChainId.HyprSepoliaTestnet]: serializedTokens.hdx.address,
    },
    token: serializedTokens.hdx,
    quoteToken: serializedTokens.hdx,
  },
  // {
  //   pid: 99999,
  //   lpSymbol: 'WETH-HYPR',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0xD25328857971917A7B0C619842043dF641cE8f32',
  //     [ChainId.HyprTestnet]: '0xD25328857971917A7B0C619842043dF641cE8f32',
  //     [ChainId.HyprSepoliaTestnet]: '0xD25328857971917A7B0C619842043dF641cE8f32',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.hypr,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'WETH-BUSD',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0x92b42145B1ECCA36dB26b156a91D35e24DC34428',
  //     [ChainId.HyprTestnet]: '0xF6fAA9125F88f092eC51A47C00c71a4cd2f2998E',
  //     [ChainId.HyprSepoliaTestnet]: '0x4021942E1Cc483328792c931D5bd5CF81F61389F',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.busd,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'WETH-DAI',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0x38b784cB59c3C3EF8F5aD240E2253c02A0d5f255',
  //     [ChainId.HyprTestnet]: '0x56c228aB00C1EA55C2B5d4dC3DEa9DCD154cdcf8',
  //     [ChainId.HyprSepoliaTestnet]: '0x7dEd29EBE540B4a27a7B2d9036A66889eDe4a9Be',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.dai,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'USDT-HDX',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0x70d24a03eF418e9e52d7C49B3857BCdDA61fDC97',
  //     [ChainId.HyprTestnet]: '0x24eAF3b692Dab29a937dF1043eBCDbF3959EaF66',
  //     [ChainId.HyprSepoliaTestnet]: '0x0C56Fac65b484c6fc4F5dfC7966fBA134b572780',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.hypr,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'USDT-BUSD',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0x057204083A720ACd0b7Dbe0b2c2648f9b45C807D',
  //     [ChainId.HyprTestnet]: '0x0A03BFa5897209D325a7C0c2E452AdC0CD3069a3',
  //     [ChainId.HyprSepoliaTestnet]: '0x708bBAcb81652ED692c297EfAffFF6633F3840b6',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.busd,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'USDT-DAI',
  //   lpAddresses: {
  //     [ChainId.HyprMainnet]: '0xf1d28742Cff940873C1C9812C39b8a061Cf5A687',
  //     [ChainId.HyprTestnet]: '0xfa79D03d163343B20D68c710117BF3268B6d8117',
  //     [ChainId.HyprSepoliaTestnet]: '0xb5ba33141828c94ae89136aa1c97AfFE311c10D1',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.dai,
  // },
]

export default farms
