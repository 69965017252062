import { ChainId } from 'hypr-toolkit/packages/swap-sdk'
import DEFAULT_TOKEN_LIST from './tokenLists/hypr-default.tokenlist.json'

const addresses = {
  weth: {
    [ChainId.HyprMainnet]: DEFAULT_TOKEN_LIST[ChainId.HyprMainnet].tokens[0],
    [ChainId.HyprTestnet]: DEFAULT_TOKEN_LIST[ChainId.HyprTestnet].tokens[0],
    [ChainId.HyprSepoliaTestnet]: DEFAULT_TOKEN_LIST[ChainId.HyprSepoliaTestnet].tokens[0],
  },
  hypr: {
    [ChainId.HyprMainnet]: DEFAULT_TOKEN_LIST[ChainId.HyprMainnet].tokens[1],
    [ChainId.HyprTestnet]: DEFAULT_TOKEN_LIST[ChainId.HyprTestnet].tokens[1],
    [ChainId.HyprSepoliaTestnet]: DEFAULT_TOKEN_LIST[ChainId.HyprSepoliaTestnet].tokens[1],
  },
  IDO: {
    [ChainId.HyprTestnet]: '0x050413c268c43Ce9036747F44d4B536fE5efe215',
    [ChainId.HyprMainnet]: '0x050413c268c43Ce9036747F44d4B536fE5efe215',
    [ChainId.HyprSepoliaTestnet]: '0x050413c268c43Ce9036747F44d4B536fE5efe215',
  },
  masterChef: {
    [ChainId.HyprMainnet]: '0x072d7Aad4D57d4adBcb6E2264cD9780b59c52b76',
    [ChainId.HyprTestnet]: '0xD18637028A5111810ba883dab87f9c475CC2be12',
    [ChainId.HyprSepoliaTestnet]: '0x5208868450ec9D86440F51B5d9e94c19B9Fe202d',
  },
  multiCall: {
    [ChainId.HyprMainnet]: '0xf6464fD81e246D1E6096cD77C50aC6ccFD6d01e5',
    [ChainId.HyprTestnet]: '0x330065Ec969B1B54220082Ba44eEd2FB8f48ea39',
    [ChainId.HyprSepoliaTestnet]: '0xf6464fD81e246D1E6096cD77C50aC6ccFD6d01e5',
  },
  sousChef: {
    [ChainId.HyprMainnet]: '0xf36Ec0D791d93e114BDC9D3Dfa2a962B1f054B48',
    [ChainId.HyprTestnet]: '0xf36Ec0D791d93e114BDC9D3Dfa2a962B1f054B48',
    [ChainId.HyprSepoliaTestnet]: '0xf36Ec0D791d93e114BDC9D3Dfa2a962B1f054B48',
  },
  lotteryV2: {
    [ChainId.HyprTestnet]: '0x5790c3534F30437641541a0FA04C992799602998',
    [ChainId.HyprMainnet]: '0x5790c3534F30437641541a0FA04C992799602998',
    [ChainId.HyprSepoliaTestnet]: '0x5790c3534F30437641541a0FA04C992799602998',
  },
  hyprProfile: {
    [ChainId.HyprTestnet]: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    [ChainId.HyprMainnet]: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    [ChainId.HyprSepoliaTestnet]: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  hyprRabbits: {
    [ChainId.HyprTestnet]: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    [ChainId.HyprMainnet]: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    [ChainId.HyprSepoliaTestnet]: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    [ChainId.HyprTestnet]: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    [ChainId.HyprMainnet]: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    [ChainId.HyprSepoliaTestnet]: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    [ChainId.HyprTestnet]: '0xE7e53A7e9E3Cf6b840f167eF69519175c4523e149',
    [ChainId.HyprMainnet]: '',
    [ChainId.HyprSepoliaTestnet]: '',
  },
  pointCenterIfo: {
    [ChainId.HyprTestnet]: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    [ChainId.HyprMainnet]: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A29523e3',
    [ChainId.HyprSepoliaTestnet]: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A29523e3',
  },
  bunnySpecial: {
    [ChainId.HyprTestnet]: '0xFee8A195570a18461146F401d6033f5ab3380849',
    [ChainId.HyprMainnet]: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    [ChainId.HyprSepoliaTestnet]: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    [ChainId.HyprTestnet]: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    [ChainId.HyprMainnet]: '0xC787F45B833721ED3aC46E99b703B3E1E01abb523',
    [ChainId.HyprSepoliaTestnet]: '0xC787F45B833721ED3aC46E99b703B3E1E01abb523',
  },
  easterNft: {
    [ChainId.HyprTestnet]: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    [ChainId.HyprMainnet]: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    [ChainId.HyprSepoliaTestnet]: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    [ChainId.HyprTestnet]: '0xfC0283b1e8801eCEf8ec1622101e0671C27E5a9B',
    [ChainId.HyprMainnet]: '0xfC0283b1e8801eCEf8ec1622101e0671C27E5a9B',
    [ChainId.HyprSepoliaTestnet]: '0xfC0283b1e8801eCEf8ec1622101e0671C27E5a9B',
  },
  predictions: {
    [ChainId.HyprTestnet]: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    [ChainId.HyprMainnet]: '',
    [ChainId.HyprSepoliaTestnet]: '',
  },
  chainlinkOracle: {
    [ChainId.HyprTestnet]: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    [ChainId.HyprMainnet]: '',
    [ChainId.HyprSepoliaTestnet]: '',
  },
  bunnySpecialCakeVault: {
    [ChainId.HyprTestnet]: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    [ChainId.HyprMainnet]: '',
    [ChainId.HyprSepoliaTestnet]: '',
  },
  bunnySpecialPrediction: {
    [ChainId.HyprTestnet]: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    [ChainId.HyprMainnet]: '',
    [ChainId.HyprSepoliaTestnet]: '',
  },
  bunnySpecialLottery: {
    [ChainId.HyprTestnet]: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    [ChainId.HyprMainnet]: '0x382cB4523110F398F0f152cae82821476AE51c9cF',
    [ChainId.HyprSepoliaTestnet]: '0x382cB4523110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    [ChainId.HyprTestnet]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [ChainId.HyprMainnet]: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    [ChainId.HyprSepoliaTestnet]: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    [ChainId.HyprTestnet]: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    [ChainId.HyprMainnet]: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    [ChainId.HyprSepoliaTestnet]: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  Oracle: {
    [ChainId.HyprTestnet]: '0xD947a4d165D7bE793C551247208eCf22b21fcbfe',
    [ChainId.HyprMainnet]: '0xcCC94d78b01D94330F25f7B8e827ef24249132DE',
    [ChainId.HyprSepoliaTestnet]: '0xcCC94d78b01D94330F25f7B8e827ef24249132DE',
  },

  TradeIn: {
    [ChainId.HyprTestnet]: '0xe48DacbCa1d0A13d0B528F81dd181726A8E9E804',
    [ChainId.HyprMainnet]: '0x63084c5aEF8a253B9e6b156e6F2ABf177994906e',
    [ChainId.HyprSepoliaTestnet]: '0x63084c5aEF8a253B9e6b156e6F2ABf177994906e',
  },
  AirDrop: {
    [ChainId.HyprTestnet]: '0x41EFAc678B414eDc5313dd1EADf078943012f14A',
    [ChainId.HyprMainnet]: '0xc7e90e63A2CF63783cA18B2EDEe7EE9Ec25a4B7C',
    [ChainId.HyprSepoliaTestnet]: '0xc7e90e63A2CF63783cA18B2EDEe7EE9Ec25a4B7C',
  },
  OOO: {
    [ChainId.HyprTestnet]: '0x9bCffe5Bb2e9D2048468A541FB1eb4069c59Fc57',
    [ChainId.HyprMainnet]: '0xe77B7DDc441B5a695d2D16020bfd5c0b0cE3aC7C',
    [ChainId.HyprSepoliaTestnet]: '0xe77B7DDc441B5a695d2D16020bfd5c0b0cE3aC7C',
  },
}
export default addresses
