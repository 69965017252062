import { ChainId, Token } from 'hypr-toolkit/packages/swap-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { ConfigChainId } from 'config'
import { SerializedToken } from './types'
import addresses from './contracts'

const { HyprMainnet, HyprTestnet, HyprSepoliaTestnet } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  weth: new Token(
    HyprMainnet,
    addresses.weth[HyprMainnet].address,
    addresses.weth[HyprMainnet].decimals,
    addresses.weth[HyprMainnet].symbol,
    addresses.weth[HyprMainnet].name,
  ),
  hypr: new Token(
    HyprMainnet,
    addresses.hypr[HyprMainnet].address,
    addresses.hypr[HyprMainnet].decimals,
    addresses.hypr[HyprMainnet].symbol,
    addresses.hypr[HyprMainnet].name,
  ),
  hdx: new Token(HyprMainnet, '0xf0971c850B853b8e0bf65D19476e03163b296A3B', 18, 'HDX', 'HDX'),
}

export const testnetTokens = {
  weth: new Token(HyprTestnet, '0xAB5670BceeC9d0eDbd6c3f149E10E5fFe7cAaD95', 18, 'WETH', 'WETH'),
  hdx: new Token(HyprTestnet, '0x384C8750EB908DEb36d57a53EeCF1aF89ae6789E', 18, 'HDX', 'HDX'),
  hypr: new Token(HyprTestnet, '0xAB5670BceeC9d0eDbd6c3f149E10E5fFe7cAaD95', 18, 'HYPR', 'HYPR'),
  usdt: new Token(HyprTestnet, '0xE14e60521041a7A05529aA6656A8dCb8d3e7fABD', 18, 'USDT', 'USDT'),
  busd: new Token(HyprTestnet, '0x3EBebB923F7faA6Fbf54D277Cb9bAB7e31Ad8C09', 18, 'BUSD', 'BUSD'),
  dai: new Token(HyprTestnet, '0xd3747fE1e8a2238FE7A7A310b508E23278973ae1', 18, 'DAI', 'DAI'),
}
export const testnetSepoliaTokens = {
  weth: new Token(
    HyprSepoliaTestnet,
    addresses.weth[ConfigChainId].address,
    addresses.weth[ConfigChainId].decimals,
    addresses.weth[ConfigChainId].symbol,
    addresses.weth[ConfigChainId].name,
  ),
  hypr: new Token(
    HyprSepoliaTestnet,
    addresses.hypr[ConfigChainId].address,
    addresses.hypr[ConfigChainId].decimals,
    addresses.hypr[ConfigChainId].symbol,
    addresses.hypr[ConfigChainId].name,
  ),
  hdx: new Token(ConfigChainId, '0xf0971c850B853b8e0bf65D19476e03163b296A3B', 18, 'HDX', 'HDX'),
}

const tokens = (): TokenList => {
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (ConfigChainId === ChainId.HyprTestnet) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  if (ConfigChainId === ChainId.HyprSepoliaTestnet) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    return Object.keys(testnetSepoliaTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetSepoliaTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
