import { chainKey } from 'config'
import React from 'react'

export type IHyprLogoProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>

const HyprLogo: React.FC<IHyprLogoProps> = (props) => {
  // eslint-disable-next-line no-restricted-globals
  return <img {...props} src={`${location.origin}/images/tokens/chain-token-round.svg`} alt={`${chainKey} logo`} />
}
export default HyprLogo
