import React from 'react'
import { Flex, IconButton, useModal } from 'hypr-toolkit/packages/uikit'
// eslint-disable-next-line import/no-unresolved
import SettingIcon from '../img/settings.svg'
import SettingsModal from './SettingsModal'

const GlobalSettings = () => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <img src={SettingIcon} alt="" />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
