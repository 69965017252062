import React, { useMemo } from 'react'
import { Trade, TradeType } from 'hypr-toolkit/packages/swap-sdk'
import styled from 'styled-components'
import { Text } from 'hypr-toolkit/packages/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { BRC20Fee } from 'config'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

const StyleText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { t } = useTranslation()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const getBrc20Fee = useMemo(() => {
    try {
      const inP = (trade.inputAmount.currency.symbol ?? '').toLowerCase()
      const out = (trade.outputAmount.currency.symbol ?? '').toLowerCase()
      if (BRC20Fee[inP]) {
        return [trade.inputAmount.currency.symbol, BRC20Fee[inP]]
      }
      if (BRC20Fee[out]) {
        return [trade.outputAmount.currency.symbol, BRC20Fee[out]]
      }
      return [undefined, undefined]
    } catch (e) {
      return [undefined, undefined]
    }
  }, [trade])
  return (
    <AutoColumn style={{ padding: '16px 16px 0' }}>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </Text>
          <QuestionHelper
            text={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
            ml="4px"
          />
        </RowFixed>
        <RowFixed>
          <StyleText fontSize="14px">
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ?? '-'}
          </StyleText>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t('Price Impact')}
          </Text>
          <QuestionHelper
            text={t('The difference between the market price and estimated price due to trade size.')}
            ml="4px"
          />
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </RowBetween>

      {getBrc20Fee[0] ? (
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" color="textSubtle">
              {getBrc20Fee[0]} fee
            </Text>
            <QuestionHelper
              text="Some tokens take a fee when they are bought or sold, which is set by the token issuer. Uniswap does not receive any of these fees."
              ml="4px"
            />
          </RowFixed>
          <StyleText fontSize="14px" color="#ffb400">
            {getBrc20Fee[1]}%
          </StyleText>
        </RowBetween>
      ) : null}

      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t('Liquidity Provider Fee')}
          </Text>
          <QuestionHelper
            text={
              <>
                <Text mb="12px">For each trade a 0.3% fee is paid</Text>
                <Text>- 0.2% to LP token holders</Text>
                <Text>- 0.1% to the protocol</Text>
              </>
            }
            ml="4px"
          />
        </RowFixed>
        <StyleText fontSize="14px">
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
        </StyleText>
      </RowBetween>
    </AutoColumn>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="0px">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <RowBetween style={{ padding: '0 16px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Text fontSize="14px" color="textSubtle">
                    {t('Route')}
                  </Text>
                  <QuestionHelper
                    text={t('Routing through these tokens resulted in the best price for your trade.')}
                    ml="4px"
                  />
                </span>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
