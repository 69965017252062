import { ChainId } from 'hypr-toolkit/packages/swap-sdk'
import { ConfigChainId } from 'config'

export const GRAPH_API_PROFILE = process.env.REACT_APP_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.REACT_APP_GRAPH_API_PREDICTION
export const GRAPH_API_LOTTERY = process.env.REACT_APP_GRAPH_API_LOTTERY
export const SNAPSHOT_VOTING_API = process.env.REACT_APP_SNAPSHOT_VOTING_API
export const SNAPSHOT_BASE_URL = process.env.REACT_APP_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.REACT_APP_API_PROFILE
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const BITQUERY_API = 'https://graphql.bitquery.io'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/hyprswap/prediction'
const info_client: Record<ChainId, string> = {
  [ChainId.HyprMainnet]: '',
  [ChainId.HyprTestnet]: 'https://testnet-graph.hypr.network/subgraphs/name/hypr/dex',
  [ChainId.HyprSepoliaTestnet]: 'https://testnet-graph.hypr.network/subgraphs/name/hypr/dex',
}
export const INFO_CLIENT = info_client[ConfigChainId]
export const BLOCKS_CLIENT = info_client[ConfigChainId]
