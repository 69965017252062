import React, { lazy, useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from 'hypr-toolkit/packages/uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { format } from 'date-fns'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import history from './routerHistory'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'

// Views included in the main bundle
import 'utils/dbg'
import GlobalCheckClaimStatus from './views/Collectibles/components/GlobalCheckClaimStatus'
import Swap from './views/Swap'
import { RedirectToSwap } from './views/Swap/redirects'
import GlobalStyle from './style/Global'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './views/AddLiquidity/redirects'
import AddLiquidity from './views/AddLiquidity'

require('typeface-poppins')

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const Farms = lazy(() => import('./views/Farms'))
// const NewFarms = lazy(() => import('./views/NewFarms'))
// const FarmAuction = lazy(() => import('./views/FarmAuction'))
// const Lottery = lazy(() => import('./views/Lottery'))
const Ifos = lazy(() => import('./views/Ifos'))
const IfosDetail = lazy(() => import('./views/Ifos/InfoDetail'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Collectibles = lazy(() => import('./views/Collectibles'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Profile = lazy(() => import('./views/Profile'))
// const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
// const Voting = lazy(() => import('./views/Voting'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
// const AddLiquidity = l azy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
// const Info = lazy(() => import('./views/Info'))
// const ComingSoon = lazy(() => import('./views/comingSoon'))
// const Pradiction = lazy(() => import('./views/Pradiction'))
// const Hdx = lazy(() => import('./views/Hdx'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  // useFetchProfile()
  // usePollCoreFarmData()
  useScrollOnRouteChange()
  useUserAgent()

  const { account } = useWeb3React()

  useEffect(() => {
    if (account && localStorage.getItem('account') !== undefined) {
      localStorage.setItem('account', account)
      window.gtag('event', 'account', {
        account: `${account}/${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
      })
    }
  }, [account])

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/swap" />
            </Route>
            <Route path="/pools">
              <Liquidity />
            </Route>
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
            <Route exact path="/ido/detail">
              <IfosDetail />
            </Route>
            <Route exact path="/ido">
              <Ifos />
            </Route>
            {/* <Route exact path="/farms/auction">
              <FarmAuction />
            </Route> */}
            {/* <Route path="/farms">
              <Farms />
            </Route> */}
            {/* */}
            {/* <Route path="/lottery">
              <Lottery />
            </Route> */}
            {/* <Route path="/collectibles">
              <Collectibles />
            </Route> */}
            {/* <Route exact path="/teams">
              <Teams />
            </Route> */}
            {/* <Route path="/teams/:id">
              <Team />
            </Route> */}
            {/* <Route path="/profile">
              <Profile />
            </Route> */}
            {/* <Route path="/competition">
              <TradingCompetition />
            </Route> */}
            {/* <Route exact path="/voting">
              <Voting />
            </Route>
            <Route exact path="/voting/proposal/create">
              <CreateProposal />
            </Route>
            <Route path="/voting/proposal/:id">
              <Proposal />
            </Route> */}
            {/* Info pages */}
            {/* <Route path="/info">
              <Info />
            </Route> */}
            {/*
            <Route path="/comingSoon">
              <ComingSoon />
            </Route> */}
            {/* <Route path="/prediction">
              <Pradiction />
            </Route> */}
            {/* <Route path="/hdx">
              <Hdx />
            </Route> */}
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            {/* <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
             <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
             */}
            {/* Redirect */}
            {/* <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
