import BigNumber from 'bignumber.js'

export function toReadable(o: Record<string, any>) {
  const res = { ...o }
  Object.keys(o).forEach((e) => {
    if (o[e] instanceof BigNumber) {
      res[e] = o[e].toNumber()
    } else if (typeof o[e] === 'object' && o[e] !== null) {
      res[e] = toReadable(o[e])
    }
  })
  return res
}
export function toReadableArray(a: Record<string, any>[]) {
  return a.map((e) => toReadable(e))
}
// @ts-ignore
window.toReadable = toReadable
// @ts-ignore
window.toReadableArray = toReadableArray
