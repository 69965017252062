import React from 'react'
import { ModalBody } from 'hypr-toolkit/packages/uikit'
import styled from 'styled-components'
import Modal from 'components/NewModal'
import MetamaskIcon from './Metamask.svg'
import WalletConnectIcon from './WalletConnect.svg'
import BitKeepIcon from './BitKeep.svg'

enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

type Login = (connectorId: ConnectorNames) => void

interface Props {
  login: Login
  onDismiss?: () => void
}

const StyleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
  margin: 0 0 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  img {
    height: 24px;
  }
`

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  const handleLogin = (title, connectorId) => {
    login(connectorId)
    localStorage.setItem('wallet', title)
    localStorage.setItem('connectorIdv2', connectorId)
    onDismiss()
  }

  return (
    <Modal title="Connect Wallet" onDismiss={onDismiss}>
      <ModalBody>
        <StyleItem onClick={() => handleLogin('Metamask', 'injected')}>
          <div>Metamask</div>
          <img src={MetamaskIcon} alt="" />
        </StyleItem>
        <StyleItem onClick={() => handleLogin('Metamask', 'walletconnect')}>
          <div>WalletConnect</div>
          <img src={WalletConnectIcon} alt="" />
        </StyleItem>
        <StyleItem onClick={() => handleLogin('BitKeep Wallet', 'injected')}>
          <div>BitKeep Wallet</div>
          <img src={BitKeepIcon} alt="" />
        </StyleItem>
        {/* <StyleText>
          New to Hypr?{' '}
          <a href="https://medium.com/hyprfoundation/1-hypr-101-setting-up-metamask-66f2896e758f" target="__blank">
            Learn more here
          </a>
        </StyleText> */}
      </ModalBody>
    </Modal>
  )
}

export default ConnectModal
