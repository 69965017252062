import React from 'react'
import styled from 'styled-components'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  Heading,
  CloseIcon,
  Button,
  IconButton,
  ArrowBackIcon,
} from 'hypr-toolkit/packages/uikit'

const StyledModalContainer = styled(ModalContainer)`
  max-width: 440px;
  background: rgb(58,71,80);
  border: 0 none;
  border-radius: 15px;
  @media screen and (max-width: 440px) {
    min-width: 320px;
  }
`

const StyledModalBody = styled(ModalBody)<{ isTitle?: boolean }>`
  padding: ${({ isTitle }) => (isTitle ? `0 24px 24px` : `24px`)};
`

const StyledModalDiv = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyleModalClose = styled(Button)`
  color: #000;
  height: 48px;
  background: none;
  box-shadow: none;
  width: 24px;
  padding: 0 10px;
`

const StyleBack = styled(IconButton)`
  background: none;
  box-shadow: none;
`

const StyleModalHeader = styled(ModalHeader)`
  border: none;
`

interface IProps {
  onBack?: () => void
  onDismiss?: () => void
  children: React.ReactNode
  footer?: React.ReactNode
  title?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export default (props: IProps): React.ReactElement | null => {
  const { onBack, title, onDismiss, children, style, footer, className } = props
  return (
    <StyledModalContainer minWidth="420px" style={style} className={className}>
      {title ? (
        <StyleModalHeader>
          <ModalTitle>
            {onBack && (
              <StyleBack onClick={onBack}>
                <ArrowBackIcon width="24px" />
              </StyleBack>
            )}
            <Heading>{title}</Heading>
          </ModalTitle>
          {onDismiss ? (
            <StyleModalClose onClick={onDismiss}>
              <CloseIcon />
            </StyleModalClose>
          ) : null}
        </StyleModalHeader>
      ) : null}
      <StyledModalBody isTitle={!!title}>
        <StyledModalDiv>{children}</StyledModalDiv>
      </StyledModalBody>
      {footer || null}
    </StyledModalContainer>
  )
}
