import React, { useState } from 'react'
import { useMatchBreakpoints } from 'hypr-toolkit/packages/uikit'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router'
// import throttle from "lodash/throttle";

// eslint-disable-next-line import/no-unresolved
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import Msg from './img/msg.svg'
import Close from './img/close.svg'
import LogoMobile from './img/mobileLogo.svg'
import MenuIcon from './img/menu.svg'

const Layout = styled.div``
const Header = styled.div<{ isTop?: boolean }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  /* ${({ isTop }) =>
    !isTop
      ? {
          backgroundColor: '#BE3144',
          backgroundImage: 'url(images/bg.svg)',
          backgroundSize: '100vw auto',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
        }
      : ''}; */
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(images/bg.svg);
  background-size: 100vw auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 0 0;
`
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const HeaderLogo = styled(Link)`
  margin: 0 132px 0 0;
`
const HeaderNav = styled.div`
  display: flex;
  align-items: center;
`
const HeaderNavItem = styled(Link)<{ cur?: string }>`
  height: 92px;
  display: flex;
  align-items: center;
  color: ${({ cur }) => (cur === 'true' ? `#fff` : `rgba(255,255,255, .6)`)};
  margin: 0 40px 0 0;
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    color: #fff;
  }
  &:after {
    display: ${({ cur }) => (cur === 'true' ? `block` : `none`)};
    content: ' ';
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background: white;
    border-radius: 2px;
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const HeaderMsg = styled.div`
  position: absolute;
  top: 92px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span,
  em {
    display: flex;
    align-items: center;
  }
  span {
    img {
      margin: 0 10px 0 0;
    }
  }
`
const StyleEM = styled.em`
  cursor: pointer;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 120px 40px 40px;
  ${({ theme }) => theme.devicesQueries.mobile} {
    display: block;
    padding: 40px 20px;
  }
`

const FooterLeft = styled.div`
  color: rgba(255, 255, 255, 0.3);
`

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.devicesQueries.mobile} {
    padding: 20px 0 0;
  }
  img {
    width: 20px;
  }
  a {
    margin: 0 0 0 20px;
    &:first-child {
      margin: 0;
    }
  }
`

// mobile
const MobileHeader = styled.div<{ showMenu?: boolean }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ showMenu }) =>
    !showMenu &&
    css`
      background-image: url(images/bg2.svg);
      background-size: 100vw auto;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: 0 -20vh;
    `}
`
const MobileLogo = styled(Link)``
const MobileNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MobileMenu = styled.img`
  margin: 0 0 0 18px;
`
const MobileMenuBox = styled.div<{ showMenu?: boolean }>`
  position: fixed;
  top: 51px;
  left: 0;
  z-index: 12;
  width: 100%;
  height: calc(100% - 51px);
  display: ${({ showMenu }) => (showMenu ? `block` : `none`)};
  transition: all 0.3s ease;
  background-color: #303841;
`

const MobileNavItem = styled(Link)<{ cur?: string }>`
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: block;
  color: ${({ cur }) => (cur === 'true' ? `#BE3144` : `#fff`)};
`

const getActive = (pathname: string): string => {
  const arr = pathname.split('/')
  if (
    arr[1] === 'pool' ||
    arr[1] === 'create' ||
    arr[1] === 'add' ||
    arr[1] === 'remove' ||
    arr[1] === 'find' ||
    arr[1] === 'liquidity'
  ) {
    return '/swap'
  }
  if (arr[1] === 'info') {
    return '/info'
  }
  return pathname
}

const social = [
  {
    name: 'twitter',
    url: 'https://twitter.com/hypr_network',
  },
  {
    name: 'telegram',
    url: 'https://t.me/hyprnetwork',
  },
  // {
  //   name: 'medium',
  //   url: 'https://medium.com/@hello.hypr.swap',
  // },
  // {
  //   name: 'discord',
  //   url: 'https://discord.gg/wUT7DmRRs4',
  // },
  // {
  //   name: 'github',
  //   url: 'https://github.com/Hyprswap',
  // },
]

const Menu = (props) => {
  const { children } = props
  const { pathname } = useLocation()
  const [showMsg, setShowMsg] = useState(false)
  const [isTop] = useState(true)
  // const refPrevOffset = useRef(window.pageYOffset);
  const { isMobile } = useMatchBreakpoints()

  const [showMenu, setShowMenu] = useState(false)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentOffset = window.pageYOffset;
  //     const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
  //     const isTopOfPage = currentOffset === 0;
  //     if (isTopOfPage) {
  //       setIsTop(true)
  //     } else if (!isBottomOfPage) {
  //       setIsTop(false)
  //     }
  //     refPrevOffset.current = currentOffset;
  //   };
  //   const throttledHandleScroll = throttle(handleScroll, 200);
  //   window.addEventListener("scroll", throttledHandleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", throttledHandleScroll);
  //   };
  // }, []);

  return (
    <Layout>
      {isMobile ? (
        <>
          <MobileHeader showMenu={showMenu}>
            <MobileLogo to="/">
              <img src={LogoMobile} alt="" width="25" />
            </MobileLogo>
            <MobileNav>
              <GlobalSettings />
              <UserMenu />
              <MobileMenu src={MenuIcon} alt="" onClick={() => setShowMenu(!showMenu)} />
            </MobileNav>
          </MobileHeader>
          <MobileMenuBox showMenu={showMenu}>
            <MobileNavItem
              cur={`${getActive(pathname) === '/swap' || getActive(pathname) === '/pools'}`}
              to="/swap"
              onClick={() => setShowMenu(false)}
            >
              Swap
            </MobileNavItem>
            {process.env.NODE_ENV !== 'production' ? (
              <>
                <MobileNavItem cur={`${getActive(pathname) === '/ido'}`} to="/ido" onClick={() => setShowMenu(false)}>
                  IDO
                </MobileNavItem>
                <MobileNavItem
                  cur={`${getActive(pathname) === '/staking'}`}
                  to="/staking"
                  onClick={() => setShowMenu(false)}
                >
                  Staking
                </MobileNavItem>
              </>
            ) : null}

            <MobileNavItem as="a" href="https://bridge.hypr.network/" target="_blank" rel="noreferrer">
              Bridge
            </MobileNavItem>
            {/* <MobileNavItem cur={`${getActive(pathname) === '/farms'}`} to="/farms" onClick={() => setShowMenu(false)}>
              Farm
            </MobileNavItem> */}
            {/* <MobileNavItem cur={`${getActive(pathname) === '/hdx'}`} to="/hdx" onClick={() => setShowMenu(false)}>
              HDX Token
            </MobileNavItem> */}
            {/* <MobileNavItem as="a" href="" target="_blank" rel="noreferrer">
              Bridge
            </MobileNavItem> */}
            {/* <MobileNavItem cur={`${getActive(pathname) === '/info'}`} to="/info" onClick={() => setShowMenu(false)}>
              Info
            </MobileNavItem> */}
            {/* <MobileNavItemTitle onClick={() => setShow(!show)}>About</MobileNavItemTitle>
            <MobileNavItemBox show={show}>
              {about.map((item) => (
                <MobileNavItemBoxLink key={item.href} href={item.href} target="_blank" rel="noreferrer">
                  {item.label}
                </MobileNavItemBoxLink>
              ))}
            </MobileNavItemBox> */}
          </MobileMenuBox>
        </>
      ) : (
        <Header isTop={isTop}>
          <HeaderLeft>
            <HeaderLogo to="/">
              <img src="/images/logo.svg" width="150" alt="" />
            </HeaderLogo>
            <HeaderNav>
              <HeaderNavItem cur={`${getActive(pathname) === '/swap' || getActive(pathname) === '/pools'}`} to="/swap">
                Swap
              </HeaderNavItem>
              {process.env.NODE_ENV !== 'production' ? (
                <>
                  <HeaderNavItem cur={`${getActive(pathname) === '/ido'}`} to="/ido" onClick={() => setShowMenu(false)}>
                    IDO
                  </HeaderNavItem>
                  <HeaderNavItem
                    cur={`${getActive(pathname) === '/staking'}`}
                    to="/staking"
                    onClick={() => setShowMenu(false)}
                  >
                    Staking
                  </HeaderNavItem>
                </>
              ) : null}
              <HeaderNavItem as="a" href="https://bridge.hypr.network/" target="_blank" rel="noreferrer">
                Bridge
              </HeaderNavItem>
              {/* <HeaderNavItem cur={`${getActive(pathname) === '/farms'}`} to="/farms">
                Farm
              </HeaderNavItem> */}
              {/* <HeaderNavItem cur={`${getActive(pathname) === '/hdx'}`} to="/hdx">
                HDX Token
              </HeaderNavItem> */}
              {/* <HeaderNavItem as="a" href="https://bridge.hypr.network/" target="_blank" rel="noreferrer">
                Bridge
              </HeaderNavItem> */}
              {/* <HeaderNavItem cur={`${getActive(pathname) === '/info'}`} to="/info">
                Info
              </HeaderNavItem> */}
              {/* <Dropdown>
                {about.map((item) => (
                  <a key={item.href} href={item.href} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                ))}
              </Dropdown> */}
            </HeaderNav>
          </HeaderLeft>
          <HeaderRight>
            <UserMenu />
          </HeaderRight>
          {showMsg && !isMobile ? (
            <HeaderMsg>
              <em />
              <span>
                <img src={Msg} alt="" />
                HyprDEX 1.0 has been fully upgraded to 2.0, and the platform’s currency HYPR has been renamed to HDX.
                Please make the necessary exchanges in a timely manner.
              </span>
              <StyleEM onClick={() => setShowMsg(false)}>
                <img src={Close} alt="" />
              </StyleEM>
            </HeaderMsg>
          ) : null}
        </Header>
      )}
      {children}
      <Footer>
        <FooterLeft>
          © {new Date().getFullYear()}{' '}
          <a href="https://www.hypr.network" target="_blank" rel="noreferrer">
            Hypr Network
          </a>
        </FooterLeft>
        <FooterRight>
          {social.map(({ name, url }) => (
            <a key={name} href={url} target="_blank" rel="noreferrer">
              <div className="social-logo">
                <img className="social-ico" src={`/images/footer/${name}.svg`} alt={name} />
              </div>
            </a>
          ))}
        </FooterRight>
      </Footer>
    </Layout>
  )
}

export default Menu
