import React from 'react'
import { Button, useModal } from 'hypr-toolkit/packages/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import theme2 from 'style/theme2'
import styled from 'styled-components'

import LegalDisclaimerModal from './LegalDisclaimerModal'
import ConnectModal from './ConnectModal'

const StyleButton = styled(Button)`
  box-shadow: none;
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const [onPresentConnectModal] = useModal(<ConnectModal login={login} />)

  let closeLegalDisclaimerModalAction
  const closeLegalDisclaimerModal = () => {
    localStorage.setItem('legal_disclaimer_accepted', 'yes')
    if (closeLegalDisclaimerModalAction) {
      closeLegalDisclaimerModalAction()
    }
    onPresentConnectModal()
  }
  const modal = <LegalDisclaimerModal closeFunction={closeLegalDisclaimerModal} />
  const [openModal, closeModal] = useModal(modal, true, true, 'legalDisclaimerModal')
  const openLegalDisclaimerModal = openModal
  closeLegalDisclaimerModalAction = closeModal

  const accepted = localStorage.getItem('legal_disclaimer_accepted') === 'yes'
  const clickBehavior = accepted ? onPresentConnectModal : openLegalDisclaimerModal

  return (
    <StyleButton
      onClick={() => {
        clickBehavior()
        window.gtag('event', 'HyprDEX_Connect_Wallet')
      }}
      {...props}
      style={{ color: theme2.buttonText, height: '48px', backgroundColor: '#ff596f' }}
    >
      {t('Connect Wallet')}
    </StyleButton>
  )
}

export default ConnectWalletButton
